<template>
	<LazyImage
		:src="avatar"
		:alt="username"
		v-if="avatar && !error"
		@error="onError"
		class="h-10.5 w-10.5 rounded-full"
	/>
	<div
		v-if="error || !avatar"
		class="flex h-10.5 w-10.5 items-center justify-center rounded-full text-center text-lg font-medium uppercase text-white"
		:class="hashAvatar"
	>
		{{ username[0] }}
	</div>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import LazyImage from './LazyImage.vue'

const props = defineProps<{
	avatar?: string
	username: string
}>()

const error = ref(false)

watch(
	() => props.avatar,
	() => {
		error.value = false
	}
)

const onError = () => {
	error.value = true
}

const hashAvatar = computed(() => {
	const colors = ['bg-pink', 'bg-cyan', 'bg-green', 'bg-lava', 'bg-orange']
	let hash = 0

	for (let i = 0; i < props.username.length; i++) {
		const char = props.username.charCodeAt(i)
		hash = (hash << 5) - hash + char
		hash = hash & hash
	}

	return colors[Math.abs(hash) % colors.length]
})
</script>
