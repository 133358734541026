<template>
	<BaseDropdown v-if="langs.length > 1">
		<template #anchor>
			<BaseButton :as="MenuButton" iconOnly>
				<template #leftIcon>
					<Component :is="selectedLangIcon" />
				</template>
			</BaseButton>
		</template>
		<template #content>
			<MenuItem v-for="lang in inactiveLangs" v-slot="{ active }">
				<li
					class="flex w-10.5 cursor-pointer items-center justify-center py-[5px] first:pt-[11px] last:pb-[11px] active:bg-fill-secondary"
					:class="{ 'bg-fill-secondary': active }"
					@click="selectLang(lang)"
				>
					<Component :is="lang.icon" class="h-5 w-5" />
				</li>
			</MenuItem>
		</template>
	</BaseDropdown>
</template>
<script setup lang="ts">
import BaseButton from './BaseButton.vue'
import BaseDropdown from './BaseDropdown.vue'
import FlagUS from '../assets/icons/flags/us.svg'
import { MenuItem, MenuButton } from '@headlessui/vue'
import { shallowRef, ShallowRef, computed, ref } from 'vue'
import { useLocaleAsync } from '../composables/useLocale'
import { useI18n } from 'vue-i18n'

type Lang = {
	code: string
	icon: ShallowRef<string> | string
}

const { locale } = useI18n()

const selectedLang = computed(() => locale.value)
const langs = ref<Lang[]>([
	{
		code: 'en',
		icon: shallowRef(FlagUS)
	}
])

const inactiveLangs = computed(() =>
	langs.value.filter((lang) => lang.code !== selectedLang.value)
)
const selectedLangIcon = computed(
	() => langs.value.find((lang) => lang.code === selectedLang.value)?.icon
)

const selectLang = (lang: Lang) => {
	useLocaleAsync(lang.code)
}
</script>
