import { createRouter, createWebHistory } from 'vue-router'
import Telegram from '../layouts/Telegram.vue'
import { useLayoutStore } from '../stores/layout'

const routes = [
	{
		path: '/',
		component: () => import('./Upload.vue')
	},
	{
		path: '/topup',
		meta: {
			layout: Telegram
		},
		component: () => import('./TopUp.vue')
	},
	{
		path: '/result/:uuid',
		component: () => import('./Result.vue')
	},
	{
		path: '/my-gallery',
		component: () => import('./Gallery.vue')
	},
	{
		path: '/:pathMatch(.*)*',
		component: () => import('./404.vue')
	}
]

export const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		// always scroll 10px above the element #main
		return {
			el: 'body',
			top: 0,
			behavior: 'smooth'
		}
	}
})

router.beforeEach((to) => {
	if (to.meta && to.meta.layout) {
		const layoutStore = useLayoutStore()

		layoutStore.setForceLayout(to.meta.layout)
	}
})
