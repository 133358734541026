<template>
	<div class="flex items-center justify-center overflow-hidden">
		<img
			v-show="loaded"
			:src="src"
			:srcset="srcset"
			:alt="alt"
			@error="onError"
			@load="onLoad"
			@loaded="onLoad"
			class="max-h-full max-w-full"
		/>
		<LoadingDots v-if="!loaded" />
	</div>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue'
import LoadingDots from './LoadingDots.vue'

const props = defineProps<{
	src: string
	srcset?: string
	alt: string
}>()

const emit = defineEmits<{
	(e: 'loaded'): void
	(e: 'error'): void
}>()

const error = ref(false)
const loaded = ref(false)

watch(
	() => props.src,
	() => {
		error.value = false
		loaded.value = false
	}
)

const onError = () => {
	emit('error')
}

const onLoad = () => {
	emit('loaded')
	loaded.value = true
}
</script>
