<template>
	<BaseFormComponent
		class="placeholder-label-tertiary placeholder:font-normal"
		:type="type"
		:placeholder="placeholder"
		v-bind="{ ...(component === 'input' && { value: modelValue }) }"
		@input="input"
		:step="step"
		:min="min"
		:max="max"
		:inputmode="forceInputmode"
		:is="component"
		:displayValue="displayValue"
	>
		<template #label>
			<slot name="label" />
		</template>
		<template #leftIcon>
			<slot name="leftIcon" />
		</template>
		<template #rightIcon>
			<slot name="rightIcon" />
		</template>
	</BaseFormComponent>
</template>
<script
	setup
	lang="ts"
	generic="
		InputType extends 'email' | 'text' | 'number' | 'password' | 'date',
		ValueType extends InputType extends 'number' ? number | null : string
	"
>
import { computed } from 'vue'
import type { Component } from 'vue'
import BaseFormComponent from './BaseFormComponent.vue'

const props = withDefaults(
	defineProps<{
		type: InputType
		modelValue?: ValueType
		placeholder?: string
		step?: number
		min?: number
		max?: number
		inputmode?:
			| 'text'
			| 'none'
			| 'tel'
			| 'url'
			| 'email'
			| 'numeric'
			| 'decimal'
			| 'search'
		component?: Component | 'input'
		displayValue?: (value: unknown) => string
	}>(),
	{
		inputmode: 'text',
		component: 'input'
	}
)

const emit = defineEmits<{
	(e: 'update:modelValue', value: ValueType): void
}>()

const input = (e: Event) => {
	let value: ValueType

	if (props.type === 'number') {
		value = Number(
			(e.target as HTMLInputElement).value.replace('\,', '\.')
		) as ValueType

		if (isNaN(value as number) && (e.target as HTMLInputElement).value) {
			value = 0 as ValueType
		}

		if (typeof props.min !== 'undefined' && (value as number) < props.min) {
			value = props.min as ValueType
		}
		if (typeof props.max !== 'undefined' && (value as number) > props.max) {
			value = props.max as ValueType
		}

		if (!(e.target as HTMLInputElement).value) {
			value = null as ValueType
		}
	} else {
		value = (e.target as HTMLInputElement).value as ValueType
	}

	emit('update:modelValue', value)
}

// const keydown = (e: KeyboardEvent) => {
// 	console.log(e)
// 	if (props.type !== 'number') {
// 		return
// 	}
// 	if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ',', 'backspace', 'enter', 'tab', 'delete'].includes(e.key.toLowerCase()) && !e.ctrlKey) {
// 		return e.preventDefault()
// 	}
// }

const type = computed(() => (props.type === 'number' ? 'text' : props.type))
const forceInputmode = computed(() =>
	props.type !== 'number'
		? props.inputmode
		: props.step && props.step % 1 !== 0
			? 'decimal'
			: 'numeric'
)
</script>
