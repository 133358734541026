import { defineAsyncComponent } from 'vue'
import SignIn from './SignIn.vue'
import SignUp from './SignUp.vue'

const modals = {
	PasswordReset: defineAsyncComponent(() => import('./PasswordReset.vue')),
	SignIn,
	SignUp,
	TopUp: defineAsyncComponent(() => import('./TopUp.vue')),
	VerifyEmail: defineAsyncComponent(() => import('./VerifyEmail.vue')),
	PaymentStatus: defineAsyncComponent(() => import('./PaymentStatus.vue')),
	AgeVerification: defineAsyncComponent(() => import('./AgeVerification.vue')),
	TermsOfService: defineAsyncComponent(() => import('./TermsOfService.vue'))
}

export default modals
