/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n\t\tmutation getInvoiceToken($initData: String) {\n\t\t\tgetInvoiceToken(initData: $initData)\n\t\t}\n\t": types.GetInvoiceTokenDocument,
    "\n\t\tmutation deleteGenerations($uuids: [UUID!]) {\n\t\t\tdeleteGenerations(uuids: $uuids)\n\t\t}\n\t": types.DeleteGenerationsDocument,
    "\n\t\tmutation deleteResult($uuid: UUID!) {\n\t\t\tdeleteResult(uuid: $uuid)\n\t\t}\n\t": types.DeleteResultDocument,
    "\n\t\tmutation resetPassword($email: EmailAddress!) {\n\t\t\tresetPassword(email: $email)\n\t\t}\n\t": types.ResetPasswordDocument,
    "\n\t\tmutation checkPasswordResetCode($email: EmailAddress!, $code: String!) {\n\t\t\tcheckPasswordResetCode(email: $email, code: $code)\n\t\t}\n\t": types.CheckPasswordResetCodeDocument,
    "\n\t\tmutation ChangePasswordWithToken(\n\t\t\t$email: EmailAddress!\n\t\t\t$newPassword: Password!\n\t\t\t$token: String!\n\t\t) {\n\t\t\tchangePasswordWithToken(\n\t\t\t\temail: $email\n\t\t\t\tnewPassword: $newPassword\n\t\t\t\ttoken: $token\n\t\t\t) {\n\t\t\t\tuuid\n\t\t\t\ttotalCredits\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tprovider\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t": types.ChangePasswordWithTokenDocument,
    "\n\t\tmutation signIn($email: EmailAddress!, $password: Password!) {\n\t\t\tsignIn(email: $email, password: $password) {\n\t\t\t\tuuid\n\t\t\t\ttotalCredits\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tprovider\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t": types.SignInDocument,
    "\n\t\tmutation oAuthSignIn($provider: AuthProvider!) {\n\t\t\toAuthSignIn(provider: $provider)\n\t\t}\n\t": types.OAuthSignInDocument,
    "\n\t\tmutation signUp(\n\t\t\t$email: EmailAddress!\n\t\t\t$password: Password!\n\t\t\t$code: String\n\t\t) {\n\t\t\tsignUp(email: $email, password: $password, referralCode: $code) {\n\t\t\t\tuuid\n\t\t\t\ttotalCredits\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tprovider\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t": types.SignUpDocument,
    "\n\t\tmutation acceptTerms {\n\t\t\tacceptTerms {\n\t\t\t\tacceptedTerms\n\t\t\t\tavatar\n\t\t\t\temailVerified\n\t\t\t\tname\n\t\t\t\tprovider\n\t\t\t\ttotalCredits\n\t\t\t\tuuid\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t": types.AcceptTermsDocument,
    "\n\t\tquery getPaymentProviders {\n\t\t\tgetPaymentProviders {\n\t\t\t\tcurrency\n\t\t\t\tproviders {\n\t\t\t\t\trequired\n\t\t\t\t\tlogo\n\t\t\t\t\tname\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t": types.GetPaymentProvidersDocument,
    "\n\t\tmutation createInvoice(\n\t\t\t$providerId: Int!\n\t\t\t$credits: Int!\n\t\t\t$data: InvoiceData\n\t\t\t$initData: String\n\t\t) {\n\t\t\tcreateInvoice(\n\t\t\t\tproviderId: $providerId\n\t\t\t\tcredits: $credits\n\t\t\t\tdata: $data\n\t\t\t\tinitData: $initData\n\t\t\t)\n\t\t}\n\t": types.CreateInvoiceDocument,
    "\n\t\tmutation sendVerifyEmail {\n\t\t\tsendVerifyEmail\n\t\t}\n\t": types.SendVerifyEmailDocument,
    "\n\t\tmutation verifyEmail($code: String!) {\n\t\t\tverifyEmail(code: $code) {\n\t\t\t\ttotalCredits\n\t\t\t\tuuid\n\t\t\t\tprovider\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t": types.VerifyEmailDocument,
    "\n\t\t\tquery getUser {\n\t\t\t\tcurrent {\n\t\t\t\t\tuuid\n\t\t\t\t\ttotalCredits\n\t\t\t\t\tname\n\t\t\t\t\temailVerified\n\t\t\t\t\tavatar\n\t\t\t\t\tprovider\n\t\t\t\t\tacceptedTerms\n\t\t\t\t\tintercomHash\n\t\t\t\t}\n\t\t\t}\n\t\t": types.GetUserDocument,
    "\n\t\t\tsubscription userUpdate {\n\t\t\t\tuserUpdate {\n\t\t\t\t\tuuid\n\t\t\t\t\ttotalCredits\n\t\t\t\t\tname\n\t\t\t\t\temailVerified\n\t\t\t\t\tavatar\n\t\t\t\t\tprovider\n\t\t\t\t\tacceptedTerms\n\t\t\t\t\tintercomHash\n\t\t\t\t}\n\t\t\t}\n\t\t": types.UserUpdateDocument,
    "\n\t\t\tsubscription balanceUpdate {\n\t\t\t\tbalanceUpdate {\n\t\t\t\t\tmessage\n\t\t\t\t\ttotalCredits\n\t\t\t\t}\n\t\t\t}\n\t\t": types.BalanceUpdateDocument,
    "\n\t\t\tmutation logout {\n\t\t\t\tlogout\n\t\t\t}\n\t\t": types.LogoutDocument,
    "\n\t\tquery getGallery($perPage: Int!, $page: Int!) {\n\t\t\tgetGallery(perPage: $perPage, page: $page) {\n\t\t\t\tgenerations {\n\t\t\t\t\tcategory\n\t\t\t\t\tcreated\n\t\t\t\t\tresults {\n\t\t\t\t\t\terror\n\t\t\t\t\t\textension\n\t\t\t\t\t\tuploaded\n\t\t\t\t\t\tuuid\n\t\t\t\t\t}\n\t\t\t\t\tstatus\n\t\t\t\t\tuuid\n\t\t\t\t\tboobsSize\n\t\t\t\t}\n\t\t\t\tpages\n\t\t\t}\n\t\t}\n\t": types.GetGalleryDocument,
    "\n\t\tsubscription generationTaskResult {\n\t\t\tgenerationTaskResult {\n\t\t\t\terror\n\t\t\t\tsuccess\n\t\t\t\tresultUuids\n\t\t\t\tgenerationUuid\n\t\t\t}\n\t\t}\n\t": types.GenerationTaskResultDocument,
    "\n\t\tmutation createGenerationByUUID(\n\t\t\t$type: GenerationTypes!\n\t\t\t$category: GenerationCategories!\n\t\t\t$uuid: UUID\n\t\t\t$size: BoobsSizes!\n\t\t) {\n\t\t\tgeneration: createGeneration(\n\t\t\t\ttype: $type\n\t\t\t\tcategory: $category\n\t\t\t\tgenerationUuid: $uuid\n\t\t\t\tboobsSize: $size\n\t\t\t) {\n\t\t\t\tuuid\n\t\t\t\tupdatedCredits\n\t\t\t}\n\t\t}\n\t": types.CreateGenerationByUuidDocument,
    "\n\t\tquery getGeneration($uuid: UUID!) {\n\t\t\tgeneration: getGeneration(uuid: $uuid) {\n\t\t\t\ttype\n\t\t\t\tstatus\n\t\t\t\tresults {\n\t\t\t\t\terror\n\t\t\t\t\textension\n\t\t\t\t\tuploaded\n\t\t\t\t\tuuid\n\t\t\t\t}\n\t\t\t\toriginals {\n\t\t\t\t\tuuid\n\t\t\t\t\textension\n\t\t\t\t\tuploaded\n\t\t\t\t}\n\t\t\t\tcreated\n\t\t\t\tcategory\n\t\t\t\tboobsSize\n\t\t\t}\n\t\t}\n\t": types.GetGenerationDocument,
    "\n\t\tmutation createGeneration(\n\t\t\t$type: GenerationTypes!\n\t\t\t$category: GenerationCategories!\n\t\t\t$size: BoobsSizes!\n\t\t\t$photos: [PhotoInput!]\n\t\t) {\n\t\t\tgeneration: createGeneration(\n\t\t\t\ttype: $type\n\t\t\t\tcategory: $category\n\t\t\t\tphotos: $photos\n\t\t\t\tboobsSize: $size\n\t\t\t) {\n\t\t\t\tuuid\n\t\t\t\tupload\n\t\t\t\tupdatedCredits\n\t\t\t}\n\t\t}\n\t": types.CreateGenerationDocument,
    "\n\t\tmutation photoUploadSuccess($uuid: UUID!) {\n\t\t\tphotoUploadSuccess(generationUuid: $uuid)\n\t\t}\n\t": types.PhotoUploadSuccessDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation getInvoiceToken($initData: String) {\n\t\t\tgetInvoiceToken(initData: $initData)\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation getInvoiceToken($initData: String) {\n\t\t\tgetInvoiceToken(initData: $initData)\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation deleteGenerations($uuids: [UUID!]) {\n\t\t\tdeleteGenerations(uuids: $uuids)\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation deleteGenerations($uuids: [UUID!]) {\n\t\t\tdeleteGenerations(uuids: $uuids)\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation deleteResult($uuid: UUID!) {\n\t\t\tdeleteResult(uuid: $uuid)\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation deleteResult($uuid: UUID!) {\n\t\t\tdeleteResult(uuid: $uuid)\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation resetPassword($email: EmailAddress!) {\n\t\t\tresetPassword(email: $email)\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation resetPassword($email: EmailAddress!) {\n\t\t\tresetPassword(email: $email)\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation checkPasswordResetCode($email: EmailAddress!, $code: String!) {\n\t\t\tcheckPasswordResetCode(email: $email, code: $code)\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation checkPasswordResetCode($email: EmailAddress!, $code: String!) {\n\t\t\tcheckPasswordResetCode(email: $email, code: $code)\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation ChangePasswordWithToken(\n\t\t\t$email: EmailAddress!\n\t\t\t$newPassword: Password!\n\t\t\t$token: String!\n\t\t) {\n\t\t\tchangePasswordWithToken(\n\t\t\t\temail: $email\n\t\t\t\tnewPassword: $newPassword\n\t\t\t\ttoken: $token\n\t\t\t) {\n\t\t\t\tuuid\n\t\t\t\ttotalCredits\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tprovider\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation ChangePasswordWithToken(\n\t\t\t$email: EmailAddress!\n\t\t\t$newPassword: Password!\n\t\t\t$token: String!\n\t\t) {\n\t\t\tchangePasswordWithToken(\n\t\t\t\temail: $email\n\t\t\t\tnewPassword: $newPassword\n\t\t\t\ttoken: $token\n\t\t\t) {\n\t\t\t\tuuid\n\t\t\t\ttotalCredits\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tprovider\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation signIn($email: EmailAddress!, $password: Password!) {\n\t\t\tsignIn(email: $email, password: $password) {\n\t\t\t\tuuid\n\t\t\t\ttotalCredits\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tprovider\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation signIn($email: EmailAddress!, $password: Password!) {\n\t\t\tsignIn(email: $email, password: $password) {\n\t\t\t\tuuid\n\t\t\t\ttotalCredits\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tprovider\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation oAuthSignIn($provider: AuthProvider!) {\n\t\t\toAuthSignIn(provider: $provider)\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation oAuthSignIn($provider: AuthProvider!) {\n\t\t\toAuthSignIn(provider: $provider)\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation signUp(\n\t\t\t$email: EmailAddress!\n\t\t\t$password: Password!\n\t\t\t$code: String\n\t\t) {\n\t\t\tsignUp(email: $email, password: $password, referralCode: $code) {\n\t\t\t\tuuid\n\t\t\t\ttotalCredits\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tprovider\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation signUp(\n\t\t\t$email: EmailAddress!\n\t\t\t$password: Password!\n\t\t\t$code: String\n\t\t) {\n\t\t\tsignUp(email: $email, password: $password, referralCode: $code) {\n\t\t\t\tuuid\n\t\t\t\ttotalCredits\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tprovider\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation acceptTerms {\n\t\t\tacceptTerms {\n\t\t\t\tacceptedTerms\n\t\t\t\tavatar\n\t\t\t\temailVerified\n\t\t\t\tname\n\t\t\t\tprovider\n\t\t\t\ttotalCredits\n\t\t\t\tuuid\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation acceptTerms {\n\t\t\tacceptTerms {\n\t\t\t\tacceptedTerms\n\t\t\t\tavatar\n\t\t\t\temailVerified\n\t\t\t\tname\n\t\t\t\tprovider\n\t\t\t\ttotalCredits\n\t\t\t\tuuid\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tquery getPaymentProviders {\n\t\t\tgetPaymentProviders {\n\t\t\t\tcurrency\n\t\t\t\tproviders {\n\t\t\t\t\trequired\n\t\t\t\t\tlogo\n\t\t\t\t\tname\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tquery getPaymentProviders {\n\t\t\tgetPaymentProviders {\n\t\t\t\tcurrency\n\t\t\t\tproviders {\n\t\t\t\t\trequired\n\t\t\t\t\tlogo\n\t\t\t\t\tname\n\t\t\t\t\tid\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation createInvoice(\n\t\t\t$providerId: Int!\n\t\t\t$credits: Int!\n\t\t\t$data: InvoiceData\n\t\t\t$initData: String\n\t\t) {\n\t\t\tcreateInvoice(\n\t\t\t\tproviderId: $providerId\n\t\t\t\tcredits: $credits\n\t\t\t\tdata: $data\n\t\t\t\tinitData: $initData\n\t\t\t)\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation createInvoice(\n\t\t\t$providerId: Int!\n\t\t\t$credits: Int!\n\t\t\t$data: InvoiceData\n\t\t\t$initData: String\n\t\t) {\n\t\t\tcreateInvoice(\n\t\t\t\tproviderId: $providerId\n\t\t\t\tcredits: $credits\n\t\t\t\tdata: $data\n\t\t\t\tinitData: $initData\n\t\t\t)\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation sendVerifyEmail {\n\t\t\tsendVerifyEmail\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation sendVerifyEmail {\n\t\t\tsendVerifyEmail\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation verifyEmail($code: String!) {\n\t\t\tverifyEmail(code: $code) {\n\t\t\t\ttotalCredits\n\t\t\t\tuuid\n\t\t\t\tprovider\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation verifyEmail($code: String!) {\n\t\t\tverifyEmail(code: $code) {\n\t\t\t\ttotalCredits\n\t\t\t\tuuid\n\t\t\t\tprovider\n\t\t\t\tname\n\t\t\t\temailVerified\n\t\t\t\tavatar\n\t\t\t\tacceptedTerms\n\t\t\t\tintercomHash\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\t\tquery getUser {\n\t\t\t\tcurrent {\n\t\t\t\t\tuuid\n\t\t\t\t\ttotalCredits\n\t\t\t\t\tname\n\t\t\t\t\temailVerified\n\t\t\t\t\tavatar\n\t\t\t\t\tprovider\n\t\t\t\t\tacceptedTerms\n\t\t\t\t\tintercomHash\n\t\t\t\t}\n\t\t\t}\n\t\t"): (typeof documents)["\n\t\t\tquery getUser {\n\t\t\t\tcurrent {\n\t\t\t\t\tuuid\n\t\t\t\t\ttotalCredits\n\t\t\t\t\tname\n\t\t\t\t\temailVerified\n\t\t\t\t\tavatar\n\t\t\t\t\tprovider\n\t\t\t\t\tacceptedTerms\n\t\t\t\t\tintercomHash\n\t\t\t\t}\n\t\t\t}\n\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\t\tsubscription userUpdate {\n\t\t\t\tuserUpdate {\n\t\t\t\t\tuuid\n\t\t\t\t\ttotalCredits\n\t\t\t\t\tname\n\t\t\t\t\temailVerified\n\t\t\t\t\tavatar\n\t\t\t\t\tprovider\n\t\t\t\t\tacceptedTerms\n\t\t\t\t\tintercomHash\n\t\t\t\t}\n\t\t\t}\n\t\t"): (typeof documents)["\n\t\t\tsubscription userUpdate {\n\t\t\t\tuserUpdate {\n\t\t\t\t\tuuid\n\t\t\t\t\ttotalCredits\n\t\t\t\t\tname\n\t\t\t\t\temailVerified\n\t\t\t\t\tavatar\n\t\t\t\t\tprovider\n\t\t\t\t\tacceptedTerms\n\t\t\t\t\tintercomHash\n\t\t\t\t}\n\t\t\t}\n\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\t\tsubscription balanceUpdate {\n\t\t\t\tbalanceUpdate {\n\t\t\t\t\tmessage\n\t\t\t\t\ttotalCredits\n\t\t\t\t}\n\t\t\t}\n\t\t"): (typeof documents)["\n\t\t\tsubscription balanceUpdate {\n\t\t\t\tbalanceUpdate {\n\t\t\t\t\tmessage\n\t\t\t\t\ttotalCredits\n\t\t\t\t}\n\t\t\t}\n\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\t\tmutation logout {\n\t\t\t\tlogout\n\t\t\t}\n\t\t"): (typeof documents)["\n\t\t\tmutation logout {\n\t\t\t\tlogout\n\t\t\t}\n\t\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tquery getGallery($perPage: Int!, $page: Int!) {\n\t\t\tgetGallery(perPage: $perPage, page: $page) {\n\t\t\t\tgenerations {\n\t\t\t\t\tcategory\n\t\t\t\t\tcreated\n\t\t\t\t\tresults {\n\t\t\t\t\t\terror\n\t\t\t\t\t\textension\n\t\t\t\t\t\tuploaded\n\t\t\t\t\t\tuuid\n\t\t\t\t\t}\n\t\t\t\t\tstatus\n\t\t\t\t\tuuid\n\t\t\t\t\tboobsSize\n\t\t\t\t}\n\t\t\t\tpages\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tquery getGallery($perPage: Int!, $page: Int!) {\n\t\t\tgetGallery(perPage: $perPage, page: $page) {\n\t\t\t\tgenerations {\n\t\t\t\t\tcategory\n\t\t\t\t\tcreated\n\t\t\t\t\tresults {\n\t\t\t\t\t\terror\n\t\t\t\t\t\textension\n\t\t\t\t\t\tuploaded\n\t\t\t\t\t\tuuid\n\t\t\t\t\t}\n\t\t\t\t\tstatus\n\t\t\t\t\tuuid\n\t\t\t\t\tboobsSize\n\t\t\t\t}\n\t\t\t\tpages\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tsubscription generationTaskResult {\n\t\t\tgenerationTaskResult {\n\t\t\t\terror\n\t\t\t\tsuccess\n\t\t\t\tresultUuids\n\t\t\t\tgenerationUuid\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tsubscription generationTaskResult {\n\t\t\tgenerationTaskResult {\n\t\t\t\terror\n\t\t\t\tsuccess\n\t\t\t\tresultUuids\n\t\t\t\tgenerationUuid\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation createGenerationByUUID(\n\t\t\t$type: GenerationTypes!\n\t\t\t$category: GenerationCategories!\n\t\t\t$uuid: UUID\n\t\t\t$size: BoobsSizes!\n\t\t) {\n\t\t\tgeneration: createGeneration(\n\t\t\t\ttype: $type\n\t\t\t\tcategory: $category\n\t\t\t\tgenerationUuid: $uuid\n\t\t\t\tboobsSize: $size\n\t\t\t) {\n\t\t\t\tuuid\n\t\t\t\tupdatedCredits\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation createGenerationByUUID(\n\t\t\t$type: GenerationTypes!\n\t\t\t$category: GenerationCategories!\n\t\t\t$uuid: UUID\n\t\t\t$size: BoobsSizes!\n\t\t) {\n\t\t\tgeneration: createGeneration(\n\t\t\t\ttype: $type\n\t\t\t\tcategory: $category\n\t\t\t\tgenerationUuid: $uuid\n\t\t\t\tboobsSize: $size\n\t\t\t) {\n\t\t\t\tuuid\n\t\t\t\tupdatedCredits\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tquery getGeneration($uuid: UUID!) {\n\t\t\tgeneration: getGeneration(uuid: $uuid) {\n\t\t\t\ttype\n\t\t\t\tstatus\n\t\t\t\tresults {\n\t\t\t\t\terror\n\t\t\t\t\textension\n\t\t\t\t\tuploaded\n\t\t\t\t\tuuid\n\t\t\t\t}\n\t\t\t\toriginals {\n\t\t\t\t\tuuid\n\t\t\t\t\textension\n\t\t\t\t\tuploaded\n\t\t\t\t}\n\t\t\t\tcreated\n\t\t\t\tcategory\n\t\t\t\tboobsSize\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tquery getGeneration($uuid: UUID!) {\n\t\t\tgeneration: getGeneration(uuid: $uuid) {\n\t\t\t\ttype\n\t\t\t\tstatus\n\t\t\t\tresults {\n\t\t\t\t\terror\n\t\t\t\t\textension\n\t\t\t\t\tuploaded\n\t\t\t\t\tuuid\n\t\t\t\t}\n\t\t\t\toriginals {\n\t\t\t\t\tuuid\n\t\t\t\t\textension\n\t\t\t\t\tuploaded\n\t\t\t\t}\n\t\t\t\tcreated\n\t\t\t\tcategory\n\t\t\t\tboobsSize\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation createGeneration(\n\t\t\t$type: GenerationTypes!\n\t\t\t$category: GenerationCategories!\n\t\t\t$size: BoobsSizes!\n\t\t\t$photos: [PhotoInput!]\n\t\t) {\n\t\t\tgeneration: createGeneration(\n\t\t\t\ttype: $type\n\t\t\t\tcategory: $category\n\t\t\t\tphotos: $photos\n\t\t\t\tboobsSize: $size\n\t\t\t) {\n\t\t\t\tuuid\n\t\t\t\tupload\n\t\t\t\tupdatedCredits\n\t\t\t}\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation createGeneration(\n\t\t\t$type: GenerationTypes!\n\t\t\t$category: GenerationCategories!\n\t\t\t$size: BoobsSizes!\n\t\t\t$photos: [PhotoInput!]\n\t\t) {\n\t\t\tgeneration: createGeneration(\n\t\t\t\ttype: $type\n\t\t\t\tcategory: $category\n\t\t\t\tphotos: $photos\n\t\t\t\tboobsSize: $size\n\t\t\t) {\n\t\t\t\tuuid\n\t\t\t\tupload\n\t\t\t\tupdatedCredits\n\t\t\t}\n\t\t}\n\t"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\t\tmutation photoUploadSuccess($uuid: UUID!) {\n\t\t\tphotoUploadSuccess(generationUuid: $uuid)\n\t\t}\n\t"): (typeof documents)["\n\t\tmutation photoUploadSuccess($uuid: UUID!) {\n\t\t\tphotoUploadSuccess(generationUuid: $uuid)\n\t\t}\n\t"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;