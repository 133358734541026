<template>
	<BaseModalWrap
		@close="modal.close()"
		v-model:show="modal.show"
		:blur="modal.blur"
	>
		<Component :is="modal.currentComponent" />
	</BaseModalWrap>
</template>
<script setup lang="ts">
import { useModalStore } from '../../stores/modal'
import BaseModalWrap from './BaseModalWrap.vue'

const modal = useModalStore()
</script>
