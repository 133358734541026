<template>
	<nav
		class="flex select-none items-center gap-3 border-b pb-[19px] pl-2.5 pr-5 pt-5 transition-[border-color] lg:pl-7"
		:class="{
			'border-transparent': !border,
			'border-separator-primary': border
		}"
	>
		<div class="flex items-center lg:gap-7">
			<MenuDropdown :routes="routes" class="lg:hidden" />
			<RouterLink to="/" custom v-slot="{ navigate }">
				<Logo
					class="min-w-fit cursor-pointer text-pink hover:text-pink-light focus:text-pink-dark active:text-pink-dark max-lg:ml-1.5"
					@click="navigate"
				/>
			</RouterLink>
			<RouterLink to="/" custom v-slot="{ navigate }">
				<BaseButton
					size="md"
					color="pink"
					@click="navigate"
					class="max-lg:ml-5"
				>
					<template #leftIcon>
						<UploadIcon />
					</template>
					{{ t('TheNavbar.newUpload') }}
				</BaseButton>
			</RouterLink>
		</div>
		<div class="flex items-center max-lg:hidden">
			<Component
				v-for="route in routes"
				:is="route.as ?? 'RouterLink'"
				:to="!route.as ? route.to : undefined"
				class="cursor-pointer truncate px-3 py-2.5 text-sm font-medium text-label-secondary hover:text-label-primary focus:text-label-primary focus:outline-none active:scale-95 active:transform"
				:exactActiveClass="!route.as ? '!text-pink' : undefined"
				:href="route.as ? route.to : undefined"
				:target="route.as ? '_blank' : undefined"
			>
				{{ route.name }}
			</Component>
		</div>
		<div class="ml-auto flex items-center gap-2.5">
			<ThemeDropdown />
			<LangDropdown />
			<LoadingSkeleton
				class="h-10.5 w-16 bg-fill-tertiary dark:bg-fill-primary"
				v-if="user.loading"
			/>
			<template v-else>
				<template v-if="user.current">
					<BaseButton size="md" color="blue" @click="open('TopUp')">
						<template #leftIcon>
							<CartIcon />
						</template>
						{{ t('TheNavbar.buyCredits') }}
					</BaseButton>
					<BaseCard
						class="flex h-10.5 items-center gap-1.5 rounded-xl pl-[11px] pr-[13px] text-sm font-medium text-label-primary"
					>
						<CreditIcon class="h-5 w-5" />
						<div>{{ user.current.totalCredits }}</div>
					</BaseCard>
					<UserDropdown>
						<UserAvatar
							:username="user.current.name"
							:avatar="user.current.avatar ?? undefined"
						/>
					</UserDropdown>
				</template>
				<BaseButton size="md" color="pink" @click="open('SignIn')" v-else>
					{{ t('TheNavbar.signIn') }}
				</BaseButton>
			</template>
		</div>
	</nav>
</template>
<script setup lang="ts">
import Logo from '../assets/icons/logo.svg'
import BaseButton from './BaseButton.vue'
import UploadIcon from '../assets/icons/upload.svg'
import CartIcon from '../assets/icons/cart.svg'
import CreditIcon from '../assets/icons/credit.svg'
import { computed } from 'vue'
import LangDropdown from './LangDropdown.vue'
import BaseCard from './BaseCard.vue'
import UserAvatar from './UserAvatar.vue'
import { useModalStore } from '../stores/modal'
import ThemeDropdown from './ThemeDropdown.vue'
import { useUserStore } from '../stores/user'
import MenuDropdown from './MenuDropdown.vue'
import { useI18n } from 'vue-i18n'
import UserDropdown from './UserDropdown.vue'
import LoadingSkeleton from './LoadingSkeleton.vue'

const { t } = useI18n()

defineProps<{
	border?: boolean
}>()

const user = useUserStore()

const routes = computed<
	{
		to: string
		name: string
		as?: 'a'
	}[]
>(() => [
	{
		to: '/my-gallery',
		name: t('TheNavbar.gallery')
	},
	{
		to: `https://p2p.nubee.ai/${user.current ? `?uuid=${user.current.uuid}` : ''}`,
		name: t('TheNavbar.p2p'),
		as: 'a'
	}
])

const { open } = useModalStore()
</script>
