<template>
	<Listbox v-model="model" :disabled="disabled">
		<div class="relative min-w-fit max-w-full select-none">
			<ListboxButton
				as="template"
				v-slot="{ open }"
				class="w-full"
				:class="{ 'cursor-not-allowed': disabled }"
			>
				<BaseFormComponent
					class="group text-left"
					is="button"
					tabindex="0"
					:error="error"
					:flat="flat"
				>
					<template #label>
						<slot name="label" />
					</template>
					<template #leftIcon v-if="selectedValue && selectedValue.icon">
						<img
							v-if="typeof selectedValue.icon === 'string'"
							:src="selectedValue.icon"
							class="h-5 w-5"
							:alt="selectedValue.name"
						/>
						<Component :is="selectedValue.icon" class="h-5 w-5" v-else />
					</template>
					<template #rightIcon>
						<Chevron
							class="transition-transform"
							:class="{ '-translate-y-px rotate-180 transform': open }"
						/>
					</template>
					<template #value v-if="selectedValue">
						{{ selectedValue.name }}
					</template>
				</BaseFormComponent>
			</ListboxButton>
			<ListboxOptions
				class="absolute left-0 right-0 z-10 max-h-64 overflow-scroll rounded-xl bg-fill-primary py-1 shadow shadow-gray/6 outline outline-1 -outline-offset-1 outline-separator-primary"
				:class="{
					'mt-2.5': placement === 'bottom',
					'bottom-10.5 mb-2.5': placement === 'top'
				}"
			>
				<ListboxOption
					v-slot="{ active, selected }"
					v-for="value in values"
					:value="value.id"
					:key="value.id"
					as="template"
				>
					<li
						class="flex h-9.5 cursor-pointer items-center gap-2 px-3 text-label-secondary hover:bg-fill-secondary hover:text-label-primary active:text-label-primary"
						:class="{ 'bg-fill-secondary text-label-primary': active }"
					>
						<template v-if="value.icon">
							<img
								:src="value.icon"
								class="h-5 w-5"
								:alt="value.name"
								v-if="typeof value.icon === 'string'"
							/>
							<Component class="h-5 w-5" :is="value.icon" v-else />
						</template>
						<div class="h-5 truncate text-sm font-medium text-label-primary">
							{{ value.name }}
						</div>
						<Check v-if="selected" class="ml-auto text-pink" />
					</li>
				</ListboxOption>
			</ListboxOptions>
		</div>
	</Listbox>
</template>
<script
	setup
	lang="ts"
	generic="
		SelectValue extends {
			id: string | number
			name: string
			icon?: string | Component
		}
	"
>
import {
	Listbox,
	ListboxButton,
	ListboxOptions,
	ListboxOption
} from '@headlessui/vue'
import Chevron from '../assets/icons/chevron-down.svg'
import Check from '../assets/icons/check.svg'
import BaseFormComponent from './BaseFormComponent.vue'
import { computed, type Component } from 'vue'

const props = withDefaults(
	defineProps<{
		values: SelectValue[]
		disabled?: boolean
		placement?: 'bottom' | 'top'
		error?: string
		flat?: boolean
	}>(),
	{
		placement: 'bottom'
	}
)

const model = defineModel<SelectValue['id']>()

const selectedValue = computed(() =>
	props.values.find((value) => value.id === model.value)
)
</script>
