<template>
	<ModalClose />
	<ModalTitleTabs
		:activeIndex="0"
		:tabs="[t('modals.SignIn.title'), t('modals.SignUp.title')]"
		@click="titleTab"
	/>
	<div class="w-full sm:w-[360px]">
		<div class="space-y-3.5">
			<BaseInput
				v-model="email"
				type="email"
				inputmode="email"
				:placeholder="t('modals.PasswordReset.email.placeholder')"
				@keydown.enter="signIn"
				autocomplete="email"
				flat
			>
				<template #label>
					{{ t('modals.PasswordReset.email.input') }}
				</template>
			</BaseInput>
			<BaseInput
				v-model="password"
				type="password"
				:placeholder="t('modals.SignIn.password.placeholder')"
				@keydown.enter="signIn"
				flat
			>
				<template #label> {{ t('modals.SignIn.password.input') }} </template>
			</BaseInput>
		</div>
		<BaseButton
			size="md"
			color="pink"
			class="mt-5 w-full"
			@click="signIn"
			:disabled="btnDisabled"
		>
			{{ t('modals.SignIn.signIn') }}
		</BaseButton>
		<div
			class="flex cursor-pointer items-center justify-center truncate p-4 text-center text-sm font-medium text-pink hover:text-label-primary active:scale-95 active:transform"
			@click="open('PasswordReset')"
		>
			{{ t('modals.SignIn.forgotPassword') }}
		</div>
		<div class="mb-4 flex items-center gap-4">
			<div class="h-px flex-1 bg-separator-primary" />
			<div class="flex-shrink-0 text-xs font-medium text-label-secondary">
				{{ t('modals.SignIn.or') }}
			</div>
			<div class="h-px flex-1 bg-separator-primary" />
		</div>
		<div class="flex gap-1.5">
			<BaseButton
				class="flex-1"
				color="custom"
				customColor="#4EAFFF"
				size="md"
				@click="oauth('telegram')"
				:disabled="oauthLoading"
			>
				<template #leftIcon>
					<TelegramIcon />
				</template>
			</BaseButton>
			<BaseButton
				class="flex-1"
				color="none"
				size="md"
				@click="oauth('google')"
				:disabled="oauthLoading"
				flat
			>
				<template #leftIcon>
					<GoogleIcon />
				</template>
			</BaseButton>
			<BaseButton
				class="flex-1"
				color="custom"
				customColor="#5865F2"
				size="md"
				@click="oauth('discord')"
				:disabled="oauthLoading"
			>
				<template #leftIcon>
					<DiscordIcon />
				</template>
			</BaseButton>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import BaseButton from '../BaseButton.vue'
import BaseInput from '../BaseInput.vue'
import ModalClose from './ModalClose.vue'
import { useModalStore } from '../../stores/modal'
import { useUserStore } from '../../stores/user'
import { EmailAddressResolver } from 'graphql-scalars'
import { useMutation } from '@vue/apollo-composable'
import { useAlertStore } from '../../stores/alert'
import { graphql } from '../../gql'
import { useI18n } from 'vue-i18n'
import TelegramIcon from '../../assets/icons/auth/telegram.svg'
import GoogleIcon from '../../assets/icons/auth/google.svg'
import DiscordIcon from '../../assets/icons/auth/discord.svg'
import { hasErrorCode } from '../../utils/hasErrorCode'
import { AuthProvider } from '../../gql/graphql'
import ModalTitleTabs from './ModalTitleTabs.vue'

const { t } = useI18n()

const titleTab = (index: number) => {
	if (index === 1) {
		open('SignUp')
	}
}

const email = ref('')
const password = ref('')

const btnDisabled = computed(
	() => loading.value || oauthLoading.value || !email.value || !password.value
)

const { open, close } = useModalStore()
const alert = useAlertStore()
const { setCurrent, refetch } = useUserStore()

const checkEmail = () => {
	try {
		EmailAddressResolver.parseValue(email.value)
		return true
	} catch {
		return false
	}
}

const checkPassword = () => {
	if (password.value.length < 8) {
		return false
	}
	if (password.value.length > 50) {
		return false
	}

	return true
}

const { mutate, loading } = useMutation(
	graphql(`
		mutation signIn($email: EmailAddress!, $password: Password!) {
			signIn(email: $email, password: $password) {
				uuid
				totalCredits
				name
				emailVerified
				avatar
				provider
				acceptedTerms
				intercomHash
			}
		}
	`)
)

const signIn = async () => {
	if (!checkEmail() || !checkPassword()) {
		return alert.push(t('modals.SignIn.invalidEmailOrPassword'))
	}

	try {
		const result = await mutate({
			email: email.value,
			password: password.value
		})

		setCurrent(result?.data?.signIn)

		close('SignIn')
	} catch (err: any) {
		if (hasErrorCode(err, 'INVALID_CREDENTIALS')) {
			return alert.push(t('modals.SignIn.invalidEmailOrPassword'))
		}
		if (hasErrorCode(err, 'ALREADY_LOGGED_IN')) {
			await refetch()
			close('SignIn')
			return
		}

		alert.push(err.message)
	}
}

const { mutate: mutateOAuth, loading: oauthLoading } = useMutation(
	graphql(`
		mutation oAuthSignIn($provider: AuthProvider!) {
			oAuthSignIn(provider: $provider)
		}
	`)
)

const oauth = async (provider: AuthProvider) => {
	try {
		const result = await mutateOAuth({ provider })

		if (!result?.data?.oAuthSignIn) {
			throw new Error(
				t('modals.SignIn.oauthUnavailable', [
					`${provider[0].toUpperCase()}${provider.slice(1)}`
				])
			)
		}

		location.href = result.data.oAuthSignIn
	} catch (err: any) {
		alert.push(err.message)
	}
}
</script>
