<template>
	<nav class="relative flex h-[72px] select-none">
		<div
			class="absolute -top-px left-0 right-0 h-px bg-separator-primary transition-opacity"
			:class="{ 'opacity-0': !border || menu, 'opacity-100': border && !menu }"
		/>
		<div
			class="absolute left-0 right-0 top-0 z-10 h-[calc(100vh-100%)] -translate-y-full transform bg-black/40"
			@click="closeMenu"
			v-if="menu"
		/>
		<div
			class="absolute bottom-0 left-0 right-0 z-20 transform rounded-t-2xl bg-fill-primary px-4 pb-[86px] pt-5 outline outline-1 -outline-offset-1 outline-separator-primary"
			v-if="menu"
		>
			<div class="flex w-full items-center gap-2.5" v-if="user.loading">
				<LoadingSkeleton class="h-10.5 w-16 flex-1 bg-fill-tertiary" />
				<LoadingSkeleton class="h-10.5 w-20 flex-1 bg-fill-tertiary" />
			</div>
			<template v-else>
				<div class="flex w-full items-center gap-3 px-1" v-if="user.current">
					<UserAvatar
						:username="user.current.name"
						:avatar="user.current.avatar ?? undefined"
					/>
					<div class="text-sm">
						<div class="text-label-secondary">
							{{ t('TheNavbarBottomMobile.loggedInAs') }}
						</div>
						<div class="font-medium text-label-primary">
							{{ user.current.name }}
						</div>
					</div>
					<BaseButton
						class="ml-auto"
						size="md"
						color="none"
						iconOnly
						@click="logoutAndClose"
					>
						<template #leftIcon>
							<LogoutIcon />
						</template>
					</BaseButton>
				</div>
				<div class="flex w-full items-center gap-2.5" v-else>
					<BaseButton
						size="md"
						color="pink"
						@click="open('SignIn')"
						class="flex-1"
					>
						{{ t('TheNavbar.signIn') }}
					</BaseButton>
					<BaseButton
						size="md"
						color="none"
						@click="open('SignUp')"
						class="flex-1"
					>
						{{ t('TheNavbar.signUp') }}
					</BaseButton>
				</div>
			</template>
			<BaseSelect
				class="mt-4"
				:modelValue="locale"
				@update:modelValue="useLocaleAsync"
				:values="langs"
				placement="top"
				v-if="langs.length > 1"
			>
				<template #label>
					{{ t('TheNavbarBottomMobile.language') }}
				</template>
			</BaseSelect>
			<BaseSelect
				class="mt-4"
				:modelValue="layout.theme"
				@update:modelValue="layout.setTheme"
				:values="themes"
				placement="top"
			>
				<template #label> {{ t('TheNavbarBottomMobile.theme') }} </template>
			</BaseSelect>
		</div>
		<NavbarBottomMobileButton
			v-for="route in mainRoutes"
			:to="!route.as ? route.to : undefined"
			:as="route.as"
			:icon="route.icon"
			:active="route.to === $route.path"
			:target="route.as ? '_blank' : undefined"
			:href="route.as ? route.to : undefined"
		>
			{{ route.name }}
		</NavbarBottomMobileButton>
		<NavbarBottomMobileButton
			as="button"
			:icon="Menu"
			:active="menu"
			@click="toggleMenu"
		>
			{{ t('TheNavbarBottomMobile.menu') }}
		</NavbarBottomMobileButton>
	</nav>
</template>
<script setup lang="ts">
import UploadIcon from '../assets/icons/upload.svg'
import CartIcon from '../assets/icons/cart.svg'
import GalleryIcon from '../assets/icons/gallery.svg'
import Menu from '../assets/icons/menu.svg'
import UserAvatar from './UserAvatar.vue'
import BaseButton from './BaseButton.vue'
import LogoutIcon from '../assets/icons/logout.svg'
import FlagUSIcon from '../assets/icons/flags/us.svg?url'
import ThemeLightIcon from '../assets/icons/theme-light.svg'
import ThemeDarkIcon from '../assets/icons/theme-dark.svg'
import ThemeAutoIcon from '../assets/icons/theme-auto.svg'
import { computed, ref, shallowRef } from 'vue'
import BaseSelect from './BaseSelect.vue'
import { useLayoutStore } from '../stores/layout'
import { useModalStore } from '../stores/modal'
import { useUserStore } from '../stores/user'
import { useI18n } from 'vue-i18n'
import { useLocaleAsync } from '../composables/useLocale'
import NavbarBottomMobileButton from './NavbarBottomMobileButton.vue'
import LoadingSkeleton from './LoadingSkeleton.vue'

const { t, locale } = useI18n()

defineProps<{
	border?: boolean
}>()

const user = useUserStore()

const mainRoutes = computed<
	{
		to: string
		name: string
		icon: string
		as?: 'a'
	}[]
>(() => [
	{
		to: '/',
		name: t('TheNavbar.upload'),
		icon: UploadIcon
	},
	{
		to: '/my-gallery',
		name: t('TheNavbar.gallery'),
		icon: GalleryIcon
	},
	{
		to: `https://p2p.nubee.ai/${user.current ? `?uuid=${user.current.uuid}` : ''}`,
		name: t('TheNavbar.p2p'),
		icon: CartIcon,
		as: 'a'
	}
])

const menu = ref(false)

const toggleMenu = () => {
	menu.value = !menu.value
}
const closeMenu = () => {
	menu.value = false
}

const langs = ref([
	{
		id: 'en',
		name: 'English (US)',
		icon: shallowRef(FlagUSIcon)
	}
])

const layout = useLayoutStore()

const themes = computed(() => [
	{
		id: 'light',
		name: t('TheNavbarBottomMobile.light'),
		icon: ThemeLightIcon
	},
	{
		id: 'dark',
		name: t('TheNavbarBottomMobile.dark'),
		icon: ThemeDarkIcon
	},
	{
		id: 'auto',
		name: `${t('TheNavbarBottomMobile.auto')} (${window.matchMedia('(prefers-color-scheme: dark)').matches ? t('TheNavbarBottomMobile.dark') : t('TheNavbarBottomMobile.light')})`,
		icon: ThemeAutoIcon
	}
])

const { open } = useModalStore()

const logoutAndClose = async () => {
	closeMenu()
	await user.logout()
}
</script>
