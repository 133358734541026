<template>
	<div class="flex h-10.5 w-10.5 items-center justify-center gap-1.5">
		<div
			class="animate-bounce-dot h-1.5 w-1.5 rounded-full bg-label-tertiary"
		/>
		<div
			class="animate-bounce-dot h-1.5 w-1.5 rounded-full bg-label-tertiary [animation-delay:_150ms]"
		/>
		<div
			class="animate-bounce-dot h-1.5 w-1.5 rounded-full bg-label-tertiary [animation-delay:_300ms]"
		/>
	</div>
</template>
<style scss>
@keyframes bounce {
	0%,
	100% {
		transform: translateY(-50%);
		opacity: 0.5;
		animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
	}
	50% {
		transform: none;
		opacity: 1;
		animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
	}
}

.animate-bounce-dot {
	animation-name: bounce;
	animation-duration: 1s;
	animation-iteration-count: infinite;
}
</style>
