import { defineStore } from 'pinia'
import { ref, onDeactivated, computed, type Component, shallowRef } from 'vue'

const INTERCOM_RIGHT = 20
const INTERCOM_BOTTOM = 20

export const useLayoutStore = defineStore('layout', () => {
	const forceLayout = shallowRef()
	const intercomRight = ref(INTERCOM_RIGHT)
	const intercomBottom = ref(INTERCOM_BOTTOM)
	const width = ref(window.innerWidth)

	const resizeHandler = (event: UIEvent) => {
		width.value = (event.target as Window).innerWidth
	}

	window.addEventListener('resize', resizeHandler)

	onDeactivated(() => {
		window.removeEventListener('resize', resizeHandler)
	})

	const setForceLayout = (layout?: Component) => {
		forceLayout.value = layout
	}

	const isMobile = computed(() => width.value < 768)
	const theme = ref<'dark' | 'light' | 'auto'>('auto')

	const setDark = (dark: boolean) => {
		if (dark) {
			document.body.classList.add('dark')
		} else {
			document.body.classList.remove('dark')
		}
	}

	const setTheme = (value: 'dark' | 'light' | 'auto') => {
		theme.value = value

		if (theme.value === 'auto') {
			localStorage.removeItem('theme')

			if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
				setDark(true)
			} else {
				setDark(false)
			}
		}

		if (theme.value === 'dark') {
			localStorage.theme = 'dark'

			setDark(true)
		}

		if (theme.value === 'light') {
			localStorage.theme = 'light'

			setDark(false)
		}
	}

	if (!('theme' in localStorage)) {
		setTheme('auto')
	} else if (localStorage.theme === 'dark') {
		setTheme('dark')
	} else {
		setTheme('light')
	}

	const updateIntercomPosition = () => {
		document.body.style.setProperty(
			'--intercom-right',
			`${intercomRight.value}px`
		)
		document.body.style.setProperty(
			'--intercom-bottom',
			`${intercomBottom.value}px`
		)
	}

	const setIntercomPosition = (pos?: { bottom?: number; right?: number }) => {
		if (!pos) {
			intercomBottom.value = INTERCOM_BOTTOM
			intercomRight.value = INTERCOM_RIGHT
		} else {
			const { bottom, right } = pos

			intercomBottom.value = bottom ?? INTERCOM_BOTTOM
			intercomRight.value = right ?? INTERCOM_RIGHT
		}

		updateIntercomPosition()
	}

	updateIntercomPosition()

	return {
		width,
		isMobile,
		setTheme,
		theme,
		forceLayout,
		setForceLayout,
		setIntercomPosition
	}
})
