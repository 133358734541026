import { createApp, h, provide } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'
import './style.css'
import App from './App.vue'
import { router } from './views'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { apolloClient } from './utils/apollo'
import { i18n } from './composables/useLocale'

const pinia = createPinia()

const app = createApp({
	setup() {
		provide(DefaultApolloClient, apolloClient)
	},

	render: () => h(App)
})

app.use(pinia).use(router).use(i18n).mount('#app')

Sentry.init({
	app,
	dsn: 'https://e733f2cf5bad085492d35ad16c33f880@sentry.onmoon.io/63',
	integrations: [
		Sentry.browserTracingIntegration({
			router
		}),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false
		})
	],
	tracesSampleRate: 0.01,
	tracePropagationTargets: [
		'localhost',
		/^https:\/\/api.nubee\.ai\//,
		/^https:\/\/dev-api.nubee\.ai\//
	],
	replaysSessionSampleRate: 0.01,
	replaysOnErrorSampleRate: 0.01
})
