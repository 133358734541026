import { defineStore } from 'pinia'
import { ref } from 'vue'

type Color = 'green' | 'red'

export const useAlertStore = defineStore('alert', () => {
	const alerts = ref<
		{
			id: number
			text: string
			color: Color
			timeout: NodeJS.Timeout
			date: Date
		}[]
	>([])

	const stopTimeout = (id: number) => {
		const alert = alerts.value.find((alert) => alert.id === id)

		if (!alert) {
			return
		}

		clearTimeout(alert.timeout)
	}

	const remove = (id: number) => {
		const index = alerts.value.findIndex((alert) => alert.id === id)

		clearTimeout(alerts.value[index].timeout)

		alerts.value.splice(index, 1)
	}

	const push = (text: string, color: Color = 'red') => {
		const id = Date.now()
		const timeout = setTimeout(() => remove(id), 5000)

		alerts.value.push({
			id,
			text,
			color,
			timeout,
			date: new Date()
		})

		if (alerts.value.length > 3) {
			alerts.value.splice(0, 1)
		}
	}

	return { alerts, stopTimeout, remove, push }
})
