<template>
	<nav
		class="flex select-none items-center justify-between border-b px-4 pb-[15px] pt-4 transition-[border-color]"
		:class="{
			'border-transparent': !border,
			'border-separator-primary': border
		}"
	>
		<RouterLink to="/" custom v-slot="{ navigate }">
			<Logo
				class="min-w-fit cursor-pointer text-pink hover:text-pink-light focus:text-pink-dark active:text-pink-dark"
				@click="navigate"
			/>
		</RouterLink>
		<LoadingSkeleton
			class="h-10.5 w-16 bg-fill-tertiary dark:bg-fill-primary"
			v-if="user.loading"
		/>
		<div class="flex gap-1.5" v-else>
			<BaseButton size="md" color="none" id="intercom-mobile" tight iconOnly>
				<template #leftIcon>
					<SupportIcon />
				</template>
			</BaseButton>
			<BaseButton
				size="md"
				color="none"
				@click="open('TopUp')"
				tight
				v-if="user.current"
			>
				<template #leftIcon>
					<CreditIcon />
				</template>
				{{ user.current.totalCredits }}
				<template #rightIcon>
					<PlusIcon class="h-5 w-5 rounded-full bg-cyan text-white" />
				</template>
			</BaseButton>
			<BaseButton size="md" color="pink" @click="open('SignIn')" v-else>
				{{ t('TheNavbar.signIn') }}
			</BaseButton>
		</div>
	</nav>
</template>
<script setup lang="ts">
import Logo from '../assets/icons/logo.svg'
import BaseButton from './BaseButton.vue'
import LoadingSkeleton from './LoadingSkeleton.vue'
import CreditIcon from '../assets/icons/credit.svg'
import PlusIcon from '../assets/icons/plus.svg'
import SupportIcon from '../assets/icons/support.svg'
import { useModalStore } from '../stores/modal'
import { useUserStore } from '../stores/user'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps<{
	border?: boolean
}>()

const { open } = useModalStore()
const user = useUserStore()
</script>
