<template>
	<BaseDropdown>
		<template #anchor>
			<BaseButton :as="MenuButton" iconOnly>
				<template #leftIcon>
					<Component :is="selectedThemeIcon" />
				</template>
			</BaseButton>
		</template>
		<template #content>
			<MenuItem v-for="theme in inactiveThemes" v-slot="{ active }">
				<li
					class="flex h-9.5 cursor-pointer items-center gap-2 px-3 first:mt-1.5 last:mb-1.5 hover:bg-fill-secondary"
					:class="{ 'bg-fill-secondary': active }"
					@click="selectLang(theme)"
				>
					<Component :is="theme.icon" class="h-5 w-5 text-label-secondary" />
					<div class="h-5 truncate text-sm font-medium text-label-primary">
						{{ theme.name }}
					</div>
				</li>
			</MenuItem>
		</template>
	</BaseDropdown>
</template>
<script setup lang="ts">
import BaseButton from './BaseButton.vue'
import BaseDropdown from './BaseDropdown.vue'
import ThemeDarkIcon from '../assets/icons/theme-dark.svg'
import ThemeLightIcon from '../assets/icons/theme-light.svg'
import ThemeAutoIcon from '../assets/icons/theme-auto.svg'
import { MenuItem, MenuButton } from '@headlessui/vue'
import { computed } from 'vue'
import { useLayoutStore } from '../stores/layout'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

type Theme = {
	id: 'light' | 'dark' | 'auto'
	name: string
	icon: string
}

const layout = useLayoutStore()

const themes = computed<Theme[]>(() => [
	{
		id: 'dark',
		name: t('TheNavbarBottomMobile.dark'),
		icon: ThemeDarkIcon
	},
	{
		id: 'light',
		name: t('TheNavbarBottomMobile.light'),
		icon: ThemeLightIcon
	},
	{
		id: 'auto',
		name: `${t('TheNavbarBottomMobile.auto')} (${window.matchMedia('(prefers-color-scheme: dark)').matches ? t('TheNavbarBottomMobile.dark') : t('TheNavbarBottomMobile.light')})`,
		icon: ThemeAutoIcon
	}
])

const inactiveThemes = computed(() =>
	themes.value.filter((theme) => theme.id !== layout.theme)
)
const selectedThemeIcon = computed(
	() => themes.value.find((theme) => theme.id === layout.theme)?.icon
)

const selectLang = (theme: Theme) => {
	layout.setTheme(theme.id)
}
</script>
