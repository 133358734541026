<template>
	<BaseDropdown align="bottom-right">
		<template #anchor>
			<MenuButton class="flex">
				<slot />
			</MenuButton>
		</template>
		<template #content>
			<MenuItem v-slot="{ active, close }">
				<li
					class="flex h-9.5 cursor-pointer items-center gap-2 truncate px-4 text-sm font-medium text-label-primary first:mt-1 last:mb-1 hover:bg-fill-secondary"
					:class="{ 'bg-fill-secondary': active }"
					@click="logoutAndClose(close)"
				>
					<LogoutIcon class="h-5 w-5 text-label-secondary" />
					<div class="h-5 truncate text-sm font-medium text-label-primary">
						{{ t('UserDropdown.logout') }}
					</div>
				</li>
			</MenuItem>
		</template>
	</BaseDropdown>
</template>
<script setup lang="ts">
import BaseDropdown from './BaseDropdown.vue'
import { MenuItem, MenuButton } from '@headlessui/vue'
import LogoutIcon from '../assets/icons/logout.svg'
import { useUserStore } from '../stores/user'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const { logout } = useUserStore()

const logoutAndClose = async (close: () => void) => {
	close()
	await logout()
}
</script>
