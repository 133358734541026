<template>
	<Teleport to="body">
		<TransitionGroup
			tag="div"
			class="pointer-events-none z-10 flex flex-col items-center gap-1"
			move-class="transition-all duration-300"
			enter-active-class="transition-all duration-300"
			leave-active-class="transition-all duration-300 absolute"
			enter-from-class="transform scale-[0.01] opacity-0"
			leave-to-class="transform scale-[0.01] opacity-0"
			v-bind="$attrs"
		>
			<BaseCard
				class="pointer-events-auto flex min-w-72 items-center gap-3 rounded-xl px-4 py-3 max-md:w-full"
				v-for="alert in alerts"
				:key="alert.id"
				@mouseover="stopTimeout(alert.id)"
				@click="remove(alert.id)"
			>
				<div
					class="h-4 w-0.5 rounded-sm"
					:class="{
						'bg-lava': alert.color === 'red',
						'bg-green': alert.color === 'green'
					}"
				/>
				<div class="text-label-primary">
					{{ alert.text }}
				</div>
			</BaseCard>
		</TransitionGroup>
	</Teleport>
</template>
<script setup lang="ts">
import { useAlertStore } from '../stores/alert'
import BaseCard from './BaseCard.vue'

const { alerts, stopTimeout, remove } = useAlertStore()

defineOptions({
	inheritAttrs: false
})
</script>
