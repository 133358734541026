<template>
	<BaseDropdown>
		<template #anchor>
			<MenuButton
				class="group flex h-10.5 items-center px-3.5 focus:outline-none"
			>
				<MenuIcon
					class="h-5 w-5 text-label-secondary group-hover:text-label-primary group-focus:text-pink"
				/>
			</MenuButton>
		</template>
		<template #content>
			<template v-for="route in routes">
				<RouterLink
					:to="route.to"
					custom
					v-slot="{ navigate, isExactActive }"
					v-if="!route.as"
					:key="route.to"
				>
					<MenuItem v-slot="{ active, close }">
						<li
							class="flex h-9.5 cursor-pointer items-center gap-2 truncate px-4 text-sm font-medium text-label-primary first:mt-1 last:mb-1 hover:bg-fill-secondary"
							:class="{
								'bg-fill-secondary': active,
								'text-pink': isExactActive
							}"
							@click="closeAndNavigate(close, navigate)"
						>
							{{ route.name }}
						</li>
					</MenuItem>
				</RouterLink>
				<MenuItem
					v-slot="{ active, close }"
					v-if="route.as === 'a'"
					:key="route.to"
				>
					<a
						:href="route.to"
						target="_blank"
						class="flex h-9.5 cursor-pointer items-center gap-2 truncate px-4 text-sm font-medium text-label-primary no-underline first:mt-1 last:mb-1 hover:bg-fill-secondary"
						:class="{
							'bg-fill-secondary': active
						}"
						@click="close()"
					>
						{{ route.name }}
					</a>
				</MenuItem>
			</template>
		</template>
	</BaseDropdown>
</template>
<script setup lang="ts">
import BaseDropdown from './BaseDropdown.vue'
import { MenuItem, MenuButton } from '@headlessui/vue'
import MenuIcon from '../assets/icons/menu-2.svg'

defineProps<{
	routes: {
		name: string
		to: string
		as?: 'a'
	}[]
}>()

const closeAndNavigate = (close: () => void, navigate: () => void) => {
	close()
	navigate()
}
</script>
