<template>
	<Component :is="layoutComponent">
		<RouterView v-slot="{ Component }">
			<transition
				enter-active-class="transition-all overflow-hidden"
				leave-active-class="transition-all overflow-hidden"
				enter-from-class="transform translate-x-20 opacity-0"
				leave-to-class="transform -translate-x-20 opacity-0"
				mode="out-in"
			>
				<Suspense :timeout="0">
					<Component :is="Component" />
					<template #fallback>
						<div class="flex h-full w-full items-center justify-center">
							<LoadingDots />
						</div>
					</template>
				</Suspense>
			</transition>
		</RouterView>
		<ModalManager />
	</Component>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, watch } from 'vue'
import Mobile from './layouts/Mobile.vue'
import Desktop from './layouts/Desktop.vue'
import { useLayoutStore } from './stores/layout'
import ModalManager from './components/modals/ModalManager.vue'
import { useModalStore } from './stores/modal'
import { useUserStore } from './stores/user'
import { useAlertStore } from './stores/alert'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import LoadingDots from './components/LoadingDots.vue'

const modal = useModalStore()

onBeforeMount(() => {
	if (!localStorage.getItem('ageVerified')) {
		modal.open('AgeVerification')
	}
})

const { t } = useI18n()
const layout = useLayoutStore()

const layoutComponent = computed(() => {
	if (layout.forceLayout) {
		return layout.forceLayout
	}
	if (layout.isMobile) {
		return Mobile
	}

	return Desktop
})

const user = useUserStore()
const alert = useAlertStore()

watch(
	() => user.current,
	(from, to) => {
		if (!from || !to || from.totalCredits >= to.totalCredits) {
			return
		}
		if (modal.currentName !== 'PaymentStatus' && modal.show) {
			return
		}

		const diff = to.totalCredits - from.totalCredits

		alert.push(t('App.credited', [diff]), 'green')
	}
)

const router = useRouter()

onMounted(() => {
	const url = new URL(location.href)
	const errorParam = url.searchParams.get('error')
	const rParam = url.searchParams.get('r')

	if (errorParam) {
		alert.push(errorParam.toString(), 'red')
		url.searchParams.delete('error')
		router.replace({ query: Object.fromEntries(url.searchParams) })
	}

	if (rParam) {
		localStorage.setItem('referralCode', rParam.toString())
		url.searchParams.delete('r')
		router.replace({ query: Object.fromEntries(url.searchParams) })
	}
})

window.intercomSettings = {
	app_id: 'mabavjtg',
	api_base: 'https://api-iam.intercom.io',
	custom_launcher_selector: '#intercom-mobile',
	alignment: 'right',
	background_color: '#ff67b8',
	action_color: '#ff58ae',
	horizontal_padding: 20,
	vertical_padding: 20
}
</script>
