<template>
	<TransitionRoot appear :show="show" as="template">
		<Dialog @close="close()">
			<TransitionChild
				as="template"
				enter="duration-300 ease-out"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="duration-200 ease-in"
				leave-from="opacity-100"
				leave-to="opacity-0"
			>
				<div
					class="fixed inset-0 bg-black/45"
					:class="{ 'backdrop-blur-xl !duration-0': blur }"
				/>
			</TransitionChild>
			<div class="fixed inset-0 overflow-y-auto">
				<div
					class="flex min-h-full items-end justify-center px-0 pb-0 pt-16.5 @container/modal sm:items-center sm:p-5"
				>
					<TransitionChild
						as="template"
						enter="duration-300 ease-out"
						enter-from="opacity-0 max-sm:translate-y-full sm:scale-95"
						enter-to="opacity-100 sm:scale-100"
						leave="duration-200 ease-in"
						leave-from="opacity-100 sm:scale-100"
						leave-to="opacity-0 max-sm:translate-y-full sm:scale-95"
					>
						<DialogPanel
							class="relative min-w-full rounded-t-2xl bg-fill-primary p-5 transition-all dark:bg-fill-secondary sm:min-w-fit sm:rounded-2xl"
						>
							<Suspense :timeout="0">
								<slot />

								<template #fallback>
									<div class="flex h-24 w-full items-center justify-center">
										<LoadingDots />
									</div>
								</template>
							</Suspense>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>
<script setup lang="ts">
import {
	Dialog,
	DialogPanel,
	TransitionRoot,
	TransitionChild
} from '@headlessui/vue'
import LoadingDots from '../LoadingDots.vue'

defineProps<{
	blur?: boolean
}>()

const emit = defineEmits<{
	(e: 'close'): void
}>()

const show = defineModel<boolean>('show')

const close = () => emit('close')
</script>
