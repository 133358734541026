<template>
	<Component
		:is="as"
		type="button"
		class="group inline-flex min-w-0 max-w-full select-none items-center justify-center gap-2 rounded-xl enabled:focus-visible:outline enabled:focus-visible:outline-1 enabled:focus-visible:outline-offset-0 enabled:focus-visible:outline-pink enabled:focus-visible:ring-4 enabled:focus-visible:ring-pink/20 dark:!ring-0"
		:class="{
			'w-10.5': size === 'md' && iconOnly,
			'w-11.5': size === 'lg' && iconOnly,
			'px-4': size === 'md' && !iconOnly && !tight,
			'px-3': size === 'md' && !iconOnly && tight,
			'px-5': size === 'lg' && !iconOnly,
			'h-10.5': size === 'md',
			'h-11.5': size === 'lg',
			'cursor-pointer': !disabled,
			'cursor-not-allowed bg-fill-quaternary': disabled,
			'shadow shadow-gray/2': color === 'none' && !disabled && !flat,
			'bg-fill-primary outline outline-1 -outline-offset-1 outline-separator-primary hover:shadow hover:shadow-gray/6 hover:outline-separator-secondary active:bg-fill-secondary active:shadow-gray/6 active:outline-separator-secondary dark:hover:bg-fill-tertiary dark:active:bg-fill-quaternary':
				color === 'none' && !disabled,
			'bg-pink hover:bg-pink-light active:bg-pink-dark':
				color === 'pink' && !disabled,
			'bg-cyan hover:bg-cyan-light active:bg-cyan-dark':
				color === 'blue' && !disabled,
			'bg-[--custom-color] hover:brightness-[110%] active:brightness-[97%]':
				color === 'custom' && !disabled
		}"
		:style="{ '--custom-color': customColor }"
		@click="click"
		:disabled="disabled"
	>
		<div
			class="h-5 w-5 empty:hidden"
			:class="{
				'text-label-secondary': disabled,
				'text-label-secondary group-hover:text-label-primary group-active:text-label-primary':
					color === 'none' && !disabled && !textColor,
				'text-white': color !== 'none' && !disabled && !textColor,
				'text-lava': textColor === 'lava' && !disabled
			}"
		>
			<slot name="leftIcon" />
		</div>
		<div
			class="max-w-full truncate text-sm empty:hidden"
			:class="{
				'text-label-secondary': disabled,
				'text-label-primary': color === 'none' && !disabled && !textColor,
				'text-lava': textColor === 'lava' && !disabled,
				'text-white': color !== 'none' && !disabled && !textColor,
				'font-medium': color === 'none' || disabled,
				'font-bold': color !== 'none' && !disabled
			}"
		>
			<slot />
		</div>
		<div
			class="h-5 w-5 empty:hidden"
			:class="{
				'text-label-secondary': disabled,
				'text-label-primary dark:text-label-secondary dark:group-hover:text-label-primary dark:group-active:text-label-primary':
					color === 'none' && !disabled && !textColor,
				'text-white': color !== 'none' && !disabled && !textColor,
				'text-lava': textColor === 'lava' && !disabled
			}"
		>
			<slot name="rightIcon" />
		</div>
	</Component>
</template>
<script setup lang="ts">
import type { Component } from 'vue'

withDefaults(
	defineProps<{
		size?: 'md' | 'lg'
		tight?: boolean
		color?: 'none' | 'pink' | 'blue' | 'custom'
		textColor?: 'lava'
		customColor?: string
		disabled?: boolean
		iconOnly?: boolean
		as?: string | Component
		flat?: boolean
	}>(),
	{
		size: 'md',
		color: 'none',
		disabled: false,
		iconOnly: false,
		as: 'button'
	}
)

const emit = defineEmits<{
	(e: 'click'): void
}>()

const click = () => emit('click')
</script>
