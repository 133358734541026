import { defineStore } from 'pinia'
import { type Component, ref, shallowRef } from 'vue'
import modals from '../components/modals'

export const useModalStore = defineStore('modal', () => {
	const currentComponent = shallowRef<Component>()
	const currentName = ref('')
	const show = ref(false)
	const blur = ref(false)
	const closable = ref(true)
	const timeout = ref<NodeJS.Timeout>()

	const open = (name: keyof typeof modals) => {
		clearTimeout(timeout.value)

		currentComponent.value = modals[name]
		currentName.value = name
		show.value = true
		closable.value = true
	}
	const close = (name?: string, force?: boolean) => {
		if (name && name !== currentName.value) {
			return
		}
		if (!closable.value && !force) {
			return
		}

		currentName.value = ''
		show.value = false
		blur.value = false

		timeout.value = setTimeout(() => {
			currentComponent.value = undefined
		}, 250)
	}
	const setClosable = (value: boolean) => (closable.value = value)
	const setBlur = (value: boolean) => (blur.value = value)

	return {
		open,
		close,
		currentComponent,
		show,
		currentName,
		setClosable,
		blur,
		setBlur
	}
})
